define("ember-cli-pagination/lib/page-items", ["exports", "ember", "ember-cli-pagination/util", "ember-cli-pagination/lib/truncate-pages", "ember-cli-pagination/util/safe-get"], function (_exports, _ember, _util, _truncatePages, _safeGet) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _ember.default.Object.extend(_safeGet.default, {
    pageItemsAll: _ember.default.computed("currentPage", "totalPages", function () {
      const currentPage = this.getInt("currentPage");
      const totalPages = this.getInt("totalPages");
      _util.default.log(`PageNumbers#pageItems, currentPage ${currentPage}, totalPages ${totalPages}`);
      let res = _ember.default.A([]);
      for (let i = 1; i <= totalPages; i++) {
        res.push({
          page: i,
          current: currentPage === i,
          dots: false
        });
      }
      return res;
    }),
    // 

    pageItemsTruncated: _ember.default.computed('currentPage', 'totalPages', 'numPagesToShow', 'showFL', function () {
      const currentPage = this.getInt('currentPage');
      const totalPages = this.getInt("totalPages");
      const toShow = this.getInt('numPagesToShow');
      const showFL = this.get('showFL');
      const t = _truncatePages.default.create({
        currentPage: currentPage,
        totalPages: totalPages,
        numPagesToShow: toShow,
        showFL: showFL
      });
      const pages = t.get('pagesToShow');
      let next = pages[0];
      return pages.map(function (page) {
        var h = {
          page: page,
          current: currentPage === page,
          dots: next !== page
        };
        next = page + 1;
        return h;
      });
    }),
    pageItems: _ember.default.computed('currentPage', 'totalPages', 'truncatePages', 'numPagesToShow', function () {
      if (this.get('truncatePages')) {
        return this.get('pageItemsTruncated');
      } else {
        return this.get('pageItemsAll');
      }
    })
  });
});