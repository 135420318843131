define("bp-ember-components/components/avatar/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <img
    data-test-avatar
    src={{if @src @src '/images/avatar.png'}}
    alt={{@alt}}
    class='w-{{@size}} h-{{@size}} {{unless @square "rounded-full"}}'
    ...attributes
  />
  */
  {
    "id": "0ioYvkV5",
    "block": "[[[11,\"img\"],[24,\"data-test-avatar\",\"\"],[16,\"src\",[52,[30,1],[30,1],\"/images/avatar.png\"]],[16,\"alt\",[30,2]],[16,0,[29,[\"w-\",[30,3],\" h-\",[30,3],\" \",[52,[51,[30,4]],\"rounded-full\"]]]],[17,5],[12],[13]],[\"@src\",\"@alt\",\"@size\",\"@square\",\"&attrs\"],false,[\"if\",\"unless\"]]",
    "moduleName": "bp-ember-components/components/avatar/index.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});