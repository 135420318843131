define("bp-ember-components/components/form/select/item/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-power-select/utils/group-utils", "@ember/service", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _groupUtils, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Form::Select
    @selected={{@selected}}
    @placeholder={{@placeholder}}
    @onChange={{@onChange}}
    @renderInPlace={{@renderInPlace}}
    @multiple={{@multiple}}
    @options={{this.initialSet}}
    @searchEnabled={{true}}
    @search={{this.search}}
    as |el|
  >
    {{#if (has-block)}}
      {{yield el}}
    {{else}}
      {{el.name}}
    {{/if}}
  </Form::Select>
  */
  {
    "id": "g/4qaqmd",
    "block": "[[[8,[39,0],null,[[\"@selected\",\"@placeholder\",\"@onChange\",\"@renderInPlace\",\"@multiple\",\"@options\",\"@searchEnabled\",\"@search\"],[[30,1],[30,2],[30,3],[30,4],[30,5],[30,0,[\"initialSet\"]],true,[30,0,[\"search\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[48,[30,7]],[[[1,\"    \"],[18,7,[[30,6]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,6,[\"name\"]]],[1,\"\\n\"]],[]]]],[6]]]]]],[\"@selected\",\"@placeholder\",\"@onChange\",\"@renderInPlace\",\"@multiple\",\"el\",\"&default\"],false,[\"form/select\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "bp-ember-components/components/form/select/item/index.hbs",
    "isStrictMode": false
  });
  let FormSelectItemComponent = _exports.default = (_class = class FormSelectItemComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "items", _descriptor, this);
      _defineProperty(this, "MAX_ITEMS", 10);
    }
    get initialSet() {
      return [...this.filter(null)];
    }
    get allItems() {
      return this.items.active;
    }
    search(needle) {
      let needles = (0, _groupUtils.stripDiacritics)(needle).toLowerCase().split(' ');
      return [...this.filter(needles)];
    }
    *filter(needles) {
      const {
        allItems
      } = this;
      let count = 0;
      let i = 0;
      while (count < this.MAX_ITEMS && i < allItems.length) {
        if (!needles || this.itemMatcher(allItems[i], needles) === 1) {
          yield allItems[i];
          count++;
        }
        i++;
      }
    }
    itemMatcher(item, needles) {
      let string = (0, _groupUtils.stripDiacritics)(item.get('scannerString')).toLowerCase();
      for (let i = 0, len = needles.length; i < len; i++) {
        if (!string.includes(needles[i])) {
          return -1;
        }
      }
      return 1;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "items", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "search", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "search"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormSelectItemComponent);
});