define("@frontile/changeset-form/components/changeset-form/index", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/debug", "@ember/runloop", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _debug, _runloop, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <form
    ...attributes
    {{on "submit" (fn this.handleSubmit @changeset)}}
    {{on "reset" (fn this.handleReset @changeset)}}
  >
  
    {{yield
      (hash
        Input=(component
          "changeset-form/fields/input"
          changeset=@changeset
          hasSubmitted=this.hasSubmitted
          showError=@alwaysShowErrors
        )
        Textarea=(component
          "changeset-form/fields/textarea"
          changeset=@changeset
          hasSubmitted=this.hasSubmitted
          showError=@alwaysShowErrors
        )
        Select=(component
          "changeset-form/fields/select"
          changeset=@changeset
          hasSubmitted=this.hasSubmitted
          showError=@alwaysShowErrors
        )
        Checkbox=(component
          "changeset-form/fields/checkbox"
          changeset=@changeset
          hasSubmitted=this.hasSubmitted
          showError=@alwaysShowErrors
        )
        CheckboxGroup=(component
          "changeset-form/fields/checkbox-group"
          changeset=@changeset
          hasSubmitted=this.hasSubmitted
          showError=@alwaysShowErrors
        )
        Radio=(component
          "changeset-form/fields/radio"
          changeset=@changeset
          hasSubmitted=this.hasSubmitted
          showError=@alwaysShowErrors
        )
        RadioGroup=(component
          "changeset-form/fields/radio-group"
          changeset=@changeset
          hasSubmitted=this.hasSubmitted
          showError=@alwaysShowErrors
        )
        state=(hash hasSubmitted=this.hasSubmitted)
      )
    }}
  
  </form>
  */
  {
    "id": "ikJlxBMy",
    "block": "[[[11,\"form\"],[17,1],[4,[38,0],[\"submit\",[28,[37,1],[[30,0,[\"handleSubmit\"]],[30,2]],null]],null],[4,[38,0],[\"reset\",[28,[37,1],[[30,0,[\"handleReset\"]],[30,2]],null]],null],[12],[1,\"\\n\\n  \"],[18,4,[[28,[37,3],null,[[\"Input\",\"Textarea\",\"Select\",\"Checkbox\",\"CheckboxGroup\",\"Radio\",\"RadioGroup\",\"state\"],[[50,\"changeset-form/fields/input\",0,null,[[\"changeset\",\"hasSubmitted\",\"showError\"],[[30,2],[30,0,[\"hasSubmitted\"]],[30,3]]]],[50,\"changeset-form/fields/textarea\",0,null,[[\"changeset\",\"hasSubmitted\",\"showError\"],[[30,2],[30,0,[\"hasSubmitted\"]],[30,3]]]],[50,\"changeset-form/fields/select\",0,null,[[\"changeset\",\"hasSubmitted\",\"showError\"],[[30,2],[30,0,[\"hasSubmitted\"]],[30,3]]]],[50,\"changeset-form/fields/checkbox\",0,null,[[\"changeset\",\"hasSubmitted\",\"showError\"],[[30,2],[30,0,[\"hasSubmitted\"]],[30,3]]]],[50,\"changeset-form/fields/checkbox-group\",0,null,[[\"changeset\",\"hasSubmitted\",\"showError\"],[[30,2],[30,0,[\"hasSubmitted\"]],[30,3]]]],[50,\"changeset-form/fields/radio\",0,null,[[\"changeset\",\"hasSubmitted\",\"showError\"],[[30,2],[30,0,[\"hasSubmitted\"]],[30,3]]]],[50,\"changeset-form/fields/radio-group\",0,null,[[\"changeset\",\"hasSubmitted\",\"showError\"],[[30,2],[30,0,[\"hasSubmitted\"]],[30,3]]]],[28,[37,3],null,[[\"hasSubmitted\"],[[30,0,[\"hasSubmitted\"]]]]]]]]]],[1,\"\\n\\n\"],[13]],[\"&attrs\",\"@changeset\",\"@alwaysShowErrors\",\"&default\"],false,[\"on\",\"fn\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@frontile/changeset-form/components/changeset-form/index.hbs",
    "isStrictMode": false
  });
  let ChangesetForm = _exports.default = (_class = class ChangesetForm extends _component2.default {
    constructor(owner, args) {
      super(owner, args);
      _initializerDefineProperty(this, "hasSubmitted", _descriptor, this);
      (false && !(this.args.changeset) && (0, _debug.assert)('@changeset must be defined on <ChangesetForm> component', this.args.changeset));
      if (this.args.validateOnInit) {
        (0, _runloop.next)(() => {
          this.args.changeset.validate();
        });
      }
    }
    async handleSubmit(changeset, event) {
      event.preventDefault();
      await changeset.validate();
      this.hasSubmitted = true;
      if (changeset.isInvalid) {
        return;
      }
      let result;
      if (this.args.runExecuteInsteadOfSave) {
        result = changeset.execute();
      } else {
        result = await changeset.save({});
      }
      if (typeof this.args.onSubmit === 'function') {
        this.args.onSubmit(result.data, event);
      }
    }
    handleReset(changeset, event) {
      event.preventDefault();
      this.hasSubmitted = false;
      const {
        data
      } = changeset.rollback();
      if (typeof this.args.onReset === 'function') {
        this.args.onReset(data, event);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "hasSubmitted", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleSubmit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleSubmit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleReset", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleReset"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChangesetForm);
});