define("@frontile/forms/components/form-radio-group", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _component2, _object, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <FormField
    @size={{@size}}
    class={{use-frontile-class "form-radio-group" @size (if @isInline "inline") class=@containerClass}}
    role="radiogroup"
    ...attributes
    as |f|
  >
    {{#if @label}}
      <f.Label
        class={{use-frontile-class "form-radio-group" @size (if @isInline "inline") part="label"}}
      >
        {{@label}}
      </f.Label>
    {{/if}}
  
    {{#if @hint}}
      <f.Hint
        class={{use-frontile-class "form-radio-group" @size (if @isInline "inline") part="hint"}}
      >
        {{@hint}}
      </f.Hint>
    {{/if}}
  
    {{yield (component "form-radio"
              checked=@value
              privateContainerClass=(use-frontile-class "form-radio-group" @size (if @isInline "inline") part="form-radio")
              _parentOnChange=this.handleChange
              size=@size
              name=(concat f.id "-radio-group")
            )
    }}
  
    {{#if this.showErrorFeedback}}
      <f.Feedback
        class={{use-frontile-class "form-radio-group" @size (if @isInline "inline") part="feedback"}}
        @errors={{@errors}}
      />
    {{/if}}
  </FormField>
  
  */
  {
    "id": "43G5Wdkc",
    "block": "[[[8,[39,0],[[16,0,[28,[37,1],[\"form-radio-group\",[30,1],[52,[30,2],\"inline\"]],[[\"class\"],[[30,3]]]]],[24,\"role\",\"radiogroup\"],[17,4]],[[\"@size\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,6],[[[1,\"    \"],[8,[30,5,[\"Label\"]],[[16,0,[28,[37,1],[\"form-radio-group\",[30,1],[52,[30,2],\"inline\"]],[[\"part\"],[\"label\"]]]]],null,[[\"default\"],[[[[1,\"\\n      \"],[1,[30,6]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,7],[[[1,\"    \"],[8,[30,5,[\"Hint\"]],[[16,0,[28,[37,1],[\"form-radio-group\",[30,1],[52,[30,2],\"inline\"]],[[\"part\"],[\"hint\"]]]]],null,[[\"default\"],[[[[1,\"\\n      \"],[1,[30,7]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[18,10,[[50,\"form-radio\",0,null,[[\"checked\",\"privateContainerClass\",\"_parentOnChange\",\"size\",\"name\"],[[30,8],[28,[37,1],[\"form-radio-group\",[30,1],[52,[30,2],\"inline\"]],[[\"part\"],[\"form-radio\"]]],[30,0,[\"handleChange\"]],[30,1],[28,[37,5],[[30,5,[\"id\"]],\"-radio-group\"],null]]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"showErrorFeedback\"]],[[[1,\"    \"],[8,[30,5,[\"Feedback\"]],[[16,0,[28,[37,1],[\"form-radio-group\",[30,1],[52,[30,2],\"inline\"]],[[\"part\"],[\"feedback\"]]]]],[[\"@errors\"],[[30,9]]],null],[1,\"\\n\"]],[]],null]],[5]]]]],[1,\"\\n\"]],[\"@size\",\"@isInline\",\"@containerClass\",\"&attrs\",\"f\",\"@label\",\"@hint\",\"@value\",\"@errors\",\"&default\"],false,[\"form-field\",\"use-frontile-class\",\"if\",\"yield\",\"component\",\"concat\"]]",
    "moduleName": "@frontile/forms/components/form-radio-group.hbs",
    "isStrictMode": false
  });
  let FormRadioGroup = _exports.default = (_class = class FormRadioGroup extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "shouldShowErrorFeedback", _descriptor, this);
    }
    get showErrorFeedback() {
      if (this.args.hasError === false) {
        return false;
      }
      if ((this.args.showError || this.args.hasSubmitted || this.shouldShowErrorFeedback) && this.args.errors && this.args.errors.length > 0) {
        return true;
      } else {
        return false;
      }
    }
    handleChange(value, event) {
      this.shouldShowErrorFeedback = true;
      if (typeof this.args.onChange === 'function') {
        this.args.onChange(value, event);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "shouldShowErrorFeedback", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleChange"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormRadioGroup);
});