define("@frontile/forms/components/form-field/label", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <label
    for={{@for}}
    class={{use-frontile-class "form-field-label" @size}}
    data-test-id="form-field-label"
    ...attributes
  >
    {{yield}}
  </label>
  
  */
  {
    "id": "KPTNti1j",
    "block": "[[[11,\"label\"],[16,\"for\",[30,1]],[16,0,[28,[37,0],[\"form-field-label\",[30,2]],null]],[17,3],[12],[1,\"\\n  \"],[18,4,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@for\",\"@size\",\"&attrs\",\"&default\"],false,[\"use-frontile-class\",\"yield\"]]",
    "moduleName": "@frontile/forms/components/form-field/label.hbs",
    "isStrictMode": false
  });
  class FormFieldLabel extends _component2.default {}
  _exports.default = FormFieldLabel;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormFieldLabel);
});