define("bp-ember-components/components/tabs/tab/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <li class='pl-2' data-test-tab>
    {{#if @model}}
      <LinkTo
        @route={{@route}}
        @model={{@model}}
        class='inline-block whitespace-nowrap rounded-t-lg border-l border-r border-t bg-gray-200 px-4 py-2'
        @activeClass='bg-white mt-px -mb-px shadow-xl border-gray-300 active'
        data-test-is-active
        ...attributes
      >
        {{yield}}
      </LinkTo>
    {{else}}
      <LinkTo
        @route={{@route}}
        class='inline-block whitespace-nowrap rounded-t-lg border-l border-r border-t bg-gray-200 px-4 py-2'
        @activeClass='bg-white mt-px -mb-px shadow-xl border-gray-300 active'
        ...attributes
      >
        {{yield}}
      </LinkTo>
    {{/if}}
  </li>
  */
  {
    "id": "SFRqntwF",
    "block": "[[[10,\"li\"],[14,0,\"pl-2\"],[14,\"data-test-tab\",\"\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[8,[39,1],[[24,0,\"inline-block whitespace-nowrap rounded-t-lg border-l border-r border-t bg-gray-200 px-4 py-2\"],[24,\"data-test-is-active\",\"\"],[17,2]],[[\"@route\",\"@model\",\"@activeClass\"],[[30,3],[30,1],\"bg-white mt-px -mb-px shadow-xl border-gray-300 active\"]],[[\"default\"],[[[[1,\"\\n      \"],[18,4,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,1],[[24,0,\"inline-block whitespace-nowrap rounded-t-lg border-l border-r border-t bg-gray-200 px-4 py-2\"],[17,2]],[[\"@route\",\"@activeClass\"],[[30,3],\"bg-white mt-px -mb-px shadow-xl border-gray-300 active\"]],[[\"default\"],[[[[1,\"\\n      \"],[18,4,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]],[13]],[\"@model\",\"&attrs\",\"@route\",\"&default\"],false,[\"if\",\"link-to\",\"yield\"]]",
    "moduleName": "bp-ember-components/components/tabs/tab/index.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});