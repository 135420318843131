define("bp-ember-components/components/form/select/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @multiple}}
    <PowerSelectMultiple
      @placeholder={{this.placeholderOrDefault}}
      @selected={{@selected}}
      @options={{@options}}
      @searchEnabled={{@searchEnabled}}
      @renderInPlace={{@renderInPlace}}
      @loadingMessage={{t 'formSelect.searching'}}
      @matcher={{@matcher}}
      @searchField={{@searchField}}
      @search={{@search}}
      @onChange={{@onChange}}
      ...attributes
      as |el|
    >
      {{yield el}}
    </PowerSelectMultiple>
  {{else}}
    <PowerSelect
      @placeholder={{this.placeholderOrDefault}}
      @selected={{@selected}}
      @options={{@options}}
      @searchEnabled={{@searchEnabled}}
      @renderInPlace={{@renderInPlace}}
      @loadingMessage={{t 'formSelect.searching'}}
      @matcher={{@matcher}}
      @searchField={{@searchField}}
      @search={{@search}}
      @onChange={{@onChange}}
      ...attributes
      as |el|
    >
      {{yield el}}
    </PowerSelect>
  {{/if}}
  */
  {
    "id": "p826Py5m",
    "block": "[[[41,[30,1],[[[1,\"  \"],[8,[39,1],[[17,2]],[[\"@placeholder\",\"@selected\",\"@options\",\"@searchEnabled\",\"@renderInPlace\",\"@loadingMessage\",\"@matcher\",\"@searchField\",\"@search\",\"@onChange\"],[[30,0,[\"placeholderOrDefault\"]],[30,3],[30,4],[30,5],[30,6],[28,[37,2],[\"formSelect.searching\"],null],[30,7],[30,8],[30,9],[30,10]]],[[\"default\"],[[[[1,\"\\n    \"],[18,13,[[30,11]]],[1,\"\\n  \"]],[11]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,4],[[17,2]],[[\"@placeholder\",\"@selected\",\"@options\",\"@searchEnabled\",\"@renderInPlace\",\"@loadingMessage\",\"@matcher\",\"@searchField\",\"@search\",\"@onChange\"],[[30,0,[\"placeholderOrDefault\"]],[30,3],[30,4],[30,5],[30,6],[28,[37,2],[\"formSelect.searching\"],null],[30,7],[30,8],[30,9],[30,10]]],[[\"default\"],[[[[1,\"\\n    \"],[18,13,[[30,12]]],[1,\"\\n  \"]],[12]]]]],[1,\"\\n\"]],[]]]],[\"@multiple\",\"&attrs\",\"@selected\",\"@options\",\"@searchEnabled\",\"@renderInPlace\",\"@matcher\",\"@searchField\",\"@search\",\"@onChange\",\"el\",\"el\",\"&default\"],false,[\"if\",\"power-select-multiple\",\"t\",\"yield\",\"power-select\"]]",
    "moduleName": "bp-ember-components/components/form/select/index.hbs",
    "isStrictMode": false
  });
  class FormSelectComponent extends _component2.default {
    get placeholderOrDefault() {
      return this.args.placeholder || '---';
    }
  }
  _exports.default = FormSelectComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormSelectComponent);
});