define("bp-ember-components/components/data-grid/valid-until/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "moment"], function (_exports, _component, _templateFactory, _component2, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <span class='{{this.color}} font-mono rounded px-3 py-2 text-xs text-white'>
    {{format-date @value year='numeric' month='2-digit' day='2-digit'}}
  </span>
  */
  {
    "id": "gorFYs7Q",
    "block": "[[[10,1],[15,0,[29,[[30,0,[\"color\"]],\" font-mono rounded px-3 py-2 text-xs text-white\"]]],[12],[1,\"\\n  \"],[1,[28,[35,0],[[30,1]],[[\"year\",\"month\",\"day\"],[\"numeric\",\"2-digit\",\"2-digit\"]]]],[1,\"\\n\"],[13]],[\"@value\"],false,[\"format-date\"]]",
    "moduleName": "bp-ember-components/components/data-grid/valid-until/index.hbs",
    "isStrictMode": false
  });
  class ExpiryDate extends _component2.default {
    get color() {
      let now = (0, _moment.default)();
      let validUntil = (0, _moment.default)(this.args.value);
      let days = validUntil.diff(now, 'days');
      console.log(now, validUntil, days);
      if (days > 60) {
        return 'bg-green-700';
      } else if (days > 30) {
        return 'bg-yellow-700';
      } else if (days > 7) {
        return 'bg-orange-700';
      } else if (days > 0) {
        return 'bg-red-700';
      } else {
        return 'bg-gray-700';
      }
    }
  }
  _exports.default = ExpiryDate;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ExpiryDate);
});