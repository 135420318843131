define("bp-ember-components/components/x-reservation/will-notify/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <i class='icons'>
    {{#unless this.value}}
      <i class='dont icon large red'></i>
    {{/unless}}
    <i class='bullhorn icon'></i>
  </i>
  */
  {
    "id": "gHreAN1s",
    "block": "[[[10,\"i\"],[14,0,\"icons\"],[12],[1,\"\\n\"],[41,[51,[30,0,[\"value\"]]],[[[1,\"    \"],[10,\"i\"],[14,0,\"dont icon large red\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,\"i\"],[14,0,\"bullhorn icon\"],[12],[13],[1,\"\\n\"],[13]],[],false,[\"unless\"]]",
    "moduleName": "bp-ember-components/components/x-reservation/will-notify/index.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});