define("bp-ember-components/components/table/td/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <td class='border-b px-2 py-4 first:pl-4 last:pr-4' ...attributes>
    {{yield}}
  </td>
  */
  {
    "id": "sHwF6nrL",
    "block": "[[[11,\"td\"],[24,0,\"border-b px-2 py-4 first:pl-4 last:pr-4\"],[17,1],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "bp-ember-components/components/table/td/index.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});