define("bp-ember-components/components/dl/item", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='grid grid-cols-2 gap-4'>
    <dt class='text-xs font-medium text-gray-500'>{{@label}}</dt>
    <dd class='text-right text-xs text-gray-900' ...attributes>
      {{yield}}
    </dd>
  </div>
  */
  {
    "id": "y9Mg5Tbv",
    "block": "[[[10,0],[14,0,\"grid grid-cols-2 gap-4\"],[12],[1,\"\\n  \"],[10,\"dt\"],[14,0,\"text-xs font-medium text-gray-500\"],[12],[1,[30,1]],[13],[1,\"\\n  \"],[11,\"dd\"],[24,0,\"text-right text-xs text-gray-900\"],[17,2],[12],[1,\"\\n    \"],[18,3,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@label\",\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "bp-ember-components/components/dl/item.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});