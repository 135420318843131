define("bp-ember-components/validations/item", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({
      regex: /[^ ]+/
    })],
    sellingPrice: [(0, _validators.validateNumber)({
      positive: true
    })],
    dispensingPrice: [(0, _validators.validateNumber)({
      positive: true
    })],
    barcode: [(0, _validators.validateFormat)({
      allowBlank: true
    })],
    warningThreshold: [(0, _validators.validateNumber)({
      positive: true
    })]
  };
});