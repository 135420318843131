define("@frontile/core/helpers/use-frontile-class", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.useFrontileClass = useFrontileClass;
  function useFrontileClass(params, hash) {
    const [base, ...variants] = params;
    if (typeof base === 'undefined') {
      return '';
    }
    const classes = [base];
    variants.forEach(variant => {
      if (variant && variant !== '') {
        classes.push(`${base}--${variant}`);
      }
    });
    if (hash.part) {
      classes.forEach((klass, index) => {
        classes[index] = `${klass}__${hash.part}`;
      });
    }
    if (hash.class) {
      if (Array.isArray(hash.class)) {
        classes.push(hash.class.join(' '));
      } else {
        classes.push(hash.class);
      }
    }
    return classes.join(' ');
  }
  var _default = _exports.default = (0, _helper.helper)(useFrontileClass);
});