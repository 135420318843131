define("ember-cli-pagination/components/page-numbers", ["exports", "ember", "ember-cli-pagination/util", "ember-cli-pagination/lib/page-items", "ember-cli-pagination/validate", "ember-cli-pagination/templates/components/page-numbers", "@ember/object"], function (_exports, _ember, _util, _pageItems, _validate, _pageNumbers, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _ember.default.Component.extend({
    layout: _pageNumbers.default,
    currentPage: _ember.default.computed.alias("content.page"),
    totalPages: _ember.default.computed.alias("content.totalPages"),
    hasPages: _ember.default.computed.gt('totalPages', 1),
    watchInvalidPage: _ember.default.observer("content", function () {
      const c = this.get('content');
      if (c && c.on) {
        c.on('invalidPage', e => {
          this._runAction('invalidPageAction', e);
        });
      }
    }),
    // only run if a closure action has been passed
    _runAction(key, ...args) {
      const action = (0, _object.get)(this, key);
      if (typeof action === 'function') {
        action(...args);
      }
    },
    truncatePages: true,
    numPagesToShow: 10,
    validate: function () {
      if (_util.default.isBlank(this.get('currentPage'))) {
        _validate.default.internalError("no currentPage for page-numbers");
      }
      if (_util.default.isBlank(this.get('totalPages'))) {
        _validate.default.internalError('no totalPages for page-numbers');
      }
    },
    pageItemsObj: _ember.default.computed(function () {
      let result = _pageItems.default.create({
        parent: this
      });
      _ember.default.defineProperty(result, 'currentPage', _ember.default.computed.alias("parent.currentPage"));
      _ember.default.defineProperty(result, 'totalPages', _ember.default.computed.alias("parent.totalPages"));
      _ember.default.defineProperty(result, 'truncatePages', _ember.default.computed.alias("parent.truncatePages"));
      _ember.default.defineProperty(result, 'numPagesToShow', _ember.default.computed.alias("parent.numPagesToShow"));
      _ember.default.defineProperty(result, 'showFL', _ember.default.computed.alias("parent.showFL"));
      return result;
    }),
    pageItems: _ember.default.computed("pageItemsObj.pageItems", "pageItemsObj", function () {
      this.validate();
      return this.get("pageItemsObj.pageItems");
    }),
    canStepForward: _ember.default.computed("currentPage", "totalPages", function () {
      const page = Number(this.get("currentPage"));
      const totalPages = Number(this.get("totalPages"));
      return page < totalPages;
    }),
    canStepBackward: _ember.default.computed("currentPage", function () {
      const page = Number(this.get("currentPage"));
      return page > 1;
    }),
    actions: {
      pageClicked: function (number) {
        _util.default.log("PageNumbers#pageClicked number " + number);
        this.set("currentPage", number);
        this._runAction('action', number);
      },
      incrementPage: function (num) {
        const currentPage = Number(this.get("currentPage")),
          totalPages = Number(this.get("totalPages"));
        if (currentPage === totalPages && num === 1) {
          return false;
        }
        if (currentPage <= 1 && num === -1) {
          return false;
        }
        this.incrementProperty('currentPage', num);
        const newPage = this.get('currentPage');
        this._runAction('action', newPage);
      }
    }
  });
});