define("bp-ember-components/components/data-grid/revenue/eet-states/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each @row.revenues as |revenue|}}
    <DataGrid::Revenue::EetStates::State @value={{revenue}} />
  {{/each}}
  */
  {
    "id": "P3UR4J33",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,1,[\"revenues\"]]],null]],null],null,[[[1,\"  \"],[8,[39,2],null,[[\"@value\"],[[30,2]]],null],[1,\"\\n\"]],[2]],null]],[\"@row\",\"revenue\"],false,[\"each\",\"-track-array\",\"data-grid/revenue/eet-states/state\"]]",
    "moduleName": "bp-ember-components/components/data-grid/revenue/eet-states/index.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});