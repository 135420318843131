define("bp-ember-components/components/form/label/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <label class='block text-sm font-bold text-gray-700' ...attributes>
    {{@label}}
    {{yield}}
  </label>
  */
  {
    "id": "V/4Qackf",
    "block": "[[[11,\"label\"],[24,0,\"block text-sm font-bold text-gray-700\"],[17,1],[12],[1,\"\\n  \"],[1,[30,2]],[1,\"\\n  \"],[18,3,null],[1,\"\\n\"],[13]],[\"&attrs\",\"@label\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "bp-ember-components/components/form/label/index.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});