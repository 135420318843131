define("ember-cli-flash/utils/object-only", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = objectWithout;
  function objectWithout(originalObj = {}, keysToRemain = []) {
    let newObj = {};
    for (let key in originalObj) {
      if (keysToRemain.indexOf(key) !== -1) {
        newObj[key] = originalObj[key];
      }
    }
    return newObj;
  }
});