define("bp-ember-components/validators/json", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateJSON;
  function validateJSON() {
    return (key, newValue) => {
      try {
        JSON.parse(newValue);
      } catch (e) {
        return e.message;
      }
      return true;
    };
  }
});