define("bp-ember-components/components/customer/visits-count", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <span
    class='inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-sm font-medium text-gray-800'
  >
    {{t 'customer.visitsCount.label' count=@visitsCount}}
  </span>
  */
  {
    "id": "LlSMXTlr",
    "block": "[[[10,1],[14,0,\"inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-sm font-medium text-gray-800\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[\"customer.visitsCount.label\"],[[\"count\"],[[30,1]]]]],[1,\"\\n\"],[13]],[\"@visitsCount\"],false,[\"t\"]]",
    "moduleName": "bp-ember-components/components/customer/visits-count.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});