define("bp-ember-components/components/table/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='w-full rounded border' ...attributes>
    <table class='w-full table-auto border-collapse'>
      {{yield}}
    </table>
  </div>
  */
  {
    "id": "TE8t17qz",
    "block": "[[[11,0],[24,0,\"w-full rounded border\"],[17,1],[12],[1,\"\\n  \"],[10,\"table\"],[14,0,\"w-full table-auto border-collapse\"],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "bp-ember-components/components/table/index.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});