define("ember-cli-pagination/page-mixin", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _ember.default.Mixin.create({
    getPage: function () {
      return parseInt(this.get('page') || 1);
    },
    getPerPage: function () {
      return parseInt(this.get('perPage'));
    }
  });
});