define("bp-ember-components/validations/settlement", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    note: [],
    headquarters: [(0, _validators.validatePresence)(true)],
    identificationNumber: [(0, _validators.validatePresence)(true)],
    vatid: [(0, _validators.validatePresence)(true)],
    issuedAt: [(0, _validators.validatePresence)(true), (0, _validators.validateDate)()],
    start: [(0, _validators.validatePresence)(true), (0, _validators.validateDate)()],
    end: [(0, _validators.validatePresence)(true), (0, _validators.validateDate)()],
    payUntil: [(0, _validators.validatePresence)(true), (0, _validators.validateDate)()],
    settlementLogo: [(0, _validators.validatePresence)(true)],
    accountNumber: [(0, _validators.validatePresence)(true)],
    invoiceNumber: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({
      integer: true,
      positive: true
    })],
    entityName: [(0, _validators.validatePresence)(true)],
    contactDetails: [(0, _validators.validatePresence)(true)]
  };
});