define("ember-cli-pagination/templates/components/page-numbers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "m18+6ITd",
    "block": "[[[10,0],[14,0,\"pagination-centered\"],[12],[1,\"\\n  \"],[10,\"ul\"],[14,0,\"pagination\"],[12],[1,\"\\n\"],[41,[33,1],[[[1,\"      \"],[10,\"li\"],[14,0,\"arrow prev enabled-arrow\"],[12],[1,\"\\n        \"],[11,3],[24,6,\"#\"],[4,[38,2],[[30,0],\"incrementPage\",-1],null],[12],[1,\"«\"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,\"li\"],[14,0,\"arrow prev disabled\"],[12],[1,\"\\n        \"],[11,3],[24,6,\"#\"],[4,[38,2],[[30,0],\"incrementPage\",-1],null],[12],[1,\"«\"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[33,5]],null]],null],null,[[[41,[30,1,[\"dots\"]],[[[1,\"        \"],[10,\"li\"],[14,0,\"dots disabled\"],[12],[1,\"\\n          \"],[10,1],[12],[1,\"...\"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[41,[30,1,[\"current\"]],[[[1,\"        \"],[10,\"li\"],[14,0,\"active page-number\"],[12],[1,\"\\n          \"],[10,3],[12],[1,[30,1,[\"page\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,\"li\"],[14,0,\"page-number\"],[12],[1,\"\\n          \"],[11,3],[24,6,\"#\"],[4,[38,2],[[30,0],\"pageClicked\",[30,1,[\"page\"]]],null],[12],[1,[30,1,[\"page\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]]],[1]],null],[1,\"\\n\"],[41,[33,6],[[[1,\"      \"],[10,\"li\"],[14,0,\"arrow next enabled-arrow\"],[12],[1,\"\\n        \"],[11,3],[24,6,\"#\"],[4,[38,2],[[30,0],\"incrementPage\",1],null],[12],[1,\"»\"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,\"li\"],[14,0,\"arrow next disabled\"],[12],[1,\"\\n        \"],[11,3],[24,6,\"#\"],[4,[38,2],[[30,0],\"incrementPage\",1],null],[12],[1,\"»\"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"item\"],false,[\"if\",\"canStepBackward\",\"action\",\"each\",\"-track-array\",\"pageItems\",\"canStepForward\"]]",
    "moduleName": "ember-cli-pagination/templates/components/page-numbers.hbs",
    "isStrictMode": false
  });
});