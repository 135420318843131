define("bp-ember-components/validations/user", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    email: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({
      type: 'email'
    })],
    password: [(0, _validators.validateLength)({
      min: 12
    })],
    passwordConfirmation: [(0, _validators.validateConfirmation)({
      on: 'password'
    })]
  };
});