define("bp-ember-components/components/money/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @value}}
    {{format-number @value style='currency' currency='CZK'}}
  {{else}}
    {{format-number 0 style='currency' currency='CZK'}}
  {{/if}}
  */
  {
    "id": "41+9D5Mu",
    "block": "[[[41,[30,1],[[[1,\"  \"],[1,[28,[35,1],[[30,1]],[[\"style\",\"currency\"],[\"currency\",\"CZK\"]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,1],[0],[[\"style\",\"currency\"],[\"currency\",\"CZK\"]]]],[1,\"\\n\"]],[]]]],[\"@value\"],false,[\"if\",\"format-number\"]]",
    "moduleName": "bp-ember-components/components/money/index.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});