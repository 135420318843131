define("bp-ember-components/components/data-grid/stock-icon/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @row.unlimited}}
    <FaIcon @icon='undo' />
  {{else}}
    <span
      class='rounded-lg px-4 py-2 text-white {{this.color}}'
      title={{this.title}}
    >
      {{this.roundedCount}}
    </span>
  {{/if}}
  */
  {
    "id": "AsJL/dOz",
    "block": "[[[41,[30,1,[\"unlimited\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@icon\"],[\"undo\"]],null],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,1],[15,0,[29,[\"rounded-lg px-4 py-2 text-white \",[30,0,[\"color\"]]]]],[15,\"title\",[30,0,[\"title\"]]],[12],[1,\"\\n    \"],[1,[30,0,[\"roundedCount\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"@row\"],false,[\"if\",\"fa-icon\"]]",
    "moduleName": "bp-ember-components/components/data-grid/stock-icon/index.hbs",
    "isStrictMode": false
  });
  class StockIcon extends _component2.default {
    get title() {
      let warningThreshold = this.args.row.warningThreshold;
      return `min: ${warningThreshold}`;
    }
    get roundedCount() {
      return Number(this.args.value).toFixed(2);
    }
    get color() {
      const current = this.args.row.stock;
      const expected = this.args.row.warningThreshold;
      if (current > expected) {
        return 'bg-green-600';
      } else if (expected === current) {
        return 'bg-yellow-600';
      } else {
        return 'bg-red-600';
      }
    }
  }
  _exports.default = StockIcon;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, StockIcon);
});