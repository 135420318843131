define("ember-table/components/-private/base-table-cell", ["exports", "@ember/component", "@ember/object/computed", "ember-table/-private/utils/observer", "@ember/runloop", "@ember/object"], function (_exports, _component, _computed, _observer, _runloop, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    // Provided by subclasses
    columnMeta: null,
    columnValue: null,
    attributeBindings: ['slackAttribute:data-test-ember-table-slack'],
    classNameBindings: ['isFirstColumn', 'isLastColumn', 'isFixedLeft', 'isFixedRight', 'textAlign', 'isSlack'],
    isFirstColumn: (0, _computed.equal)('columnMeta.index', 0),
    isLastColumn: (0, _computed.readOnly)('columnMeta.isLastRendered'),
    isFixedLeft: (0, _computed.equal)('columnMeta.isFixed', 'left'),
    isFixedRight: (0, _computed.equal)('columnMeta.isFixed', 'right'),
    isSlack: (0, _computed.readOnly)('columnMeta.isSlack'),
    // prevents `data-test-ember-table-slack="false"` on non-slack cells in Ember 2.4
    slackAttribute: (0, _object.computed)('isSlack', function () {
      return this.get('isSlack') ? true : null;
    }),
    /**
     Indicates the text alignment of this cell
    */
    textAlign: (0, _object.computed)('columnValue.textAlign', function () {
      let textAlign = this.get('columnValue.textAlign');
      if (['left', 'center', 'right'].includes(textAlign)) {
        return `ember-table__text-align-${textAlign}`;
      }
      return null;
    }),
    // eslint-disable-next-line
    scheduleUpdateStyles: (0, _observer.observer)('columnMeta.{width,offsetLeft,offsetRight}', 'isFixedLeft', 'isFixedRight', function () {
      (0, _runloop.scheduleOnce)('actions', this, 'updateStyles');
    }),
    updateStyles() {
      if (typeof FastBoot === 'undefined' && this.element) {
        let width = `${this.get('columnMeta.width')}px`;
        this.element.style.width = width;
        this.element.style.minWidth = width;
        this.element.style.maxWidth = width;
        if (this.get('isFixedLeft')) {
          this.element.style.left = `${Math.round(this.get('columnMeta.offsetLeft'))}px`;
        } else if (this.get('isFixedRight')) {
          this.element.style.right = `${Math.round(this.get('columnMeta.offsetRight'))}px`;
        }
        if (this.get('isSlack')) {
          this.element.style.paddingLeft = 0;
          this.element.style.paddingRight = 0;
          this.element.style.display = width === '0px' ? 'none' : 'table-cell';
        }
      }
    },
    didInsertElement() {
      this._super(...arguments);
      this.updateStyles();
    }
  });
});