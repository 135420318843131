define("bp-ember-components/components/eet-state/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!-- <UiPopup @html={{this.message}}> --}}
  <FaIcon @icon={{this.icon}} alt={{this.message}} class={{this.color}} />
  {{! </UiPopup> }}
  */
  {
    "id": "LJSEEpkv",
    "block": "[[[8,[39,0],[[16,\"alt\",[30,0,[\"message\"]]],[16,0,[30,0,[\"color\"]]]],[[\"@icon\"],[[30,0,[\"icon\"]]]],null],[1,\"\\n\"]],[],false,[\"fa-icon\"]]",
    "moduleName": "bp-ember-components/components/eet-state/index.hbs",
    "isStrictMode": false
  });
  const STATUS_ICONS = {
    not_needed: 'circle',
    sent: 'check',
    will_send: 'hourglass',
    failed: 'exclamation'
  };
  const STATUS_COLORS = {
    not_needed: 'green',
    sent: 'green',
    will_send: 'grey',
    failed: 'red'
  };

  // TODO: this is the same file as ui/bp-ember-components/addon/components/data-grid/revenue/eet-states/state/index.js
  class EetState extends _component2.default {
    get state() {
      return this.args.value.eetState;
    }
    get fik() {
      return this.args.value.eetFik;
    }
    get bkp() {
      return this.args.value.eetBkp;
    }
    get response() {
      return JSON.stringify(this.args.value.eetResponse);
    }
    get icon() {
      return STATUS_ICONS[this.state];
    }
    get color() {
      return `text-${STATUS_COLORS[this.state]}-600`;
    }
    get message() {
      return `<dl><dt>status:</dt><dd>
      ${this.state}</dd>
    <dt>response:</dt><dd>${this.response}</dd><dt>fik</dt><dd>${this.fik}</dd><dt>bkp</dt><dd>${this.bkp}</dd>`;
    }
  }
  _exports.default = EetState;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EetState);
});