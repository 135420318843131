define("bp-ember-components/validators/fromDate", ["exports", "moment", "ember-changeset-validations/utils/validation-errors"], function (_exports, _moment, _validationErrors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateFromDate;
  function validateFromDate() {
    return (key, newValue, oldValue, changes, content) => {
      if ((0, _moment.default)(newValue).isBefore((0, _moment.default)(content.end))) {
        return true;
      }
      return (0, _validationErrors.default)('fromDate', {
        type: 'lessThan'
      });
    };
  }
});