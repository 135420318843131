define("ember-cli-pagination/remote/controller-mixin", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _ember.default.Mixin.create({
    queryParams: ["page", "perPage"],
    page: _ember.default.computed.alias("model.page"),
    totalPages: _ember.default.computed.alias("model.totalPages"),
    pagedContent: _ember.default.computed.alias("model")
  });
});