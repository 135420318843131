define("ember-invoke-action/index", ["exports", "@ember/object/mixin", "@ember/debug", "@ember/object"], function (_exports, _mixin, _debug, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.strictInvokeAction = _exports.strictInvoke = _exports.invokeAction = _exports.invoke = _exports.default = _exports.InvokeActionMixin = void 0;
  const makeInvokeAction = ({
    strict = false
  } = {}) => {
    return (object, actionName, ...args) => {
      (false && !(typeof object === 'object') && (0, _debug.assert)('The first argument passed to invokeAction must be an object', typeof object === 'object'));
      let action;
      if (typeof actionName === 'string') {
        action = (0, _object.get)(object, actionName);
      } else if (typeof actionName === 'function') {
        action = actionName;
      } else {
        (false && !(false) && (0, _debug.assert)('The second argument passed to invokeAction must be a string as actionName or a function', false));
      }
      if (typeof action === 'string') {
        object.sendAction(actionName, ...args);
      } else if (typeof action === 'function') {
        return action(...args);
      } else if (strict) {
        (false && !(false) && (0, _debug.assert)(`No invokable action ${actionName} was found`, false));
      }
    };
  };
  const getActions = object => {
    return object.actions ? object.actions : object._actions;
  };
  const makeInvoke = ({
    strict = false
  } = {}) => {
    return (object, actionName, ...args) => {
      let actions = getActions(object);
      let action = actions && actions[actionName];
      if (typeof action === 'function') {
        return action.call(object, ...args);
      } else if (strict) {
        (false && !(false) && (0, _debug.assert)(`No invokable action ${actionName} was found`, false));
      }
    };
  };
  const invokeAction = _exports.invokeAction = makeInvokeAction();
  const strictInvokeAction = _exports.strictInvokeAction = makeInvokeAction({
    strict: true
  });
  const invoke = _exports.invoke = makeInvoke();
  const strictInvoke = _exports.strictInvoke = makeInvoke({
    strict: true
  });
  const InvokeActionMixin = _exports.InvokeActionMixin = _mixin.default.create({
    invokeAction() {
      return invokeAction(this, ...arguments);
    },
    strictInvokeAction() {
      return strictInvokeAction(this, ...arguments);
    },
    invoke() {
      return invoke(this, ...arguments);
    },
    strictInvoke() {
      return strictInvoke(this, ...arguments);
    }
  });
  var _default = _exports.default = invokeAction;
});