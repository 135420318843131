define("bp-ember-components/components/dd-menu/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <BasicDropdown class='inline-block text-sm lg:flex-grow' as |dd|>
    <dd.Trigger class='px-6 py-4' ...attributes>
      {{yield (hash Trigger=(component 'dd-menu/trigger'))}}
    </dd.Trigger>
    <dd.Content class='rounded-lg border bg-white shadow-md'>
      {{yield (hash Item=(component 'dd-menu/item'))}}
    </dd.Content>
  </BasicDropdown>
  */
  {
    "id": "QNHOjVkq",
    "block": "[[[8,[39,0],[[24,0,\"inline-block text-sm lg:flex-grow\"]],null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"Trigger\"]],[[24,0,\"px-6 py-4\"],[17,2]],null,[[\"default\"],[[[[1,\"\\n    \"],[18,3,[[28,[37,2],null,[[\"Trigger\"],[[50,\"dd-menu/trigger\",0,null,null]]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[30,1,[\"Content\"]],[[24,0,\"rounded-lg border bg-white shadow-md\"]],null,[[\"default\"],[[[[1,\"\\n    \"],[18,3,[[28,[37,2],null,[[\"Item\"],[[50,\"dd-menu/item\",0,null,null]]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"dd\",\"&attrs\",\"&default\"],false,[\"basic-dropdown\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "bp-ember-components/components/dd-menu/index.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});