define("bp-ember-components/validations/supply", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    quantity: [],
    // [validateNumber({ positive: true })],
    purchasePrice: [],
    // [validateNumber({ positive: true })],
    vat: [(0, _validators.validateNumber)({
      positive: true,
      integer: true
    })]
  };
});