define("bp-ember-components/components/message/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    data-test-wages-left
    class='relative my-2 rounded border px-4 py-3 {{this.color}}'
    role='alert'
    ...attributes
  >
    <p>
      {{#if @title}}
        <strong class='font-bold'>
          {{@title}}:
        </strong>
      {{/if}}
      {{yield}}
    </p>
  </div>
  */
  {
    "id": "keudZYv/",
    "block": "[[[11,0],[24,\"data-test-wages-left\",\"\"],[16,0,[29,[\"relative my-2 rounded border px-4 py-3 \",[30,0,[\"color\"]]]]],[24,\"role\",\"alert\"],[17,1],[12],[1,\"\\n  \"],[10,2],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"      \"],[10,\"strong\"],[14,0,\"font-bold\"],[12],[1,\"\\n        \"],[1,[30,2]],[1,\":\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[18,3,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@title\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "bp-ember-components/components/message/index.hbs",
    "isStrictMode": false
  });
  class MessageIndexComponent extends _component2.default {
    get color() {
      switch (this.args.type) {
        case 'success':
          return 'bg-green-100 border-green-400 text-green-700';
        case 'danger':
          return 'bg-red-100 border-red-400 text-red-700';
        case 'info':
          return 'bg-blue-100 border-blue-400 text-blue-700';
        default:
          return 'bg-gray-100 border-gray-400 text-gray-700';
      }
    }
  }
  _exports.default = MessageIndexComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MessageIndexComponent);
});