define("bp-ember-components/components/form/select/item-multiple/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Form::Select::Item
    @selected={{@selected}}
    @placeholder={{@placeholder}}
    @renderInPlace={{@renderInPlace}}
    @onChange={{@onChange}}
    @multiple={{true}}
    @options={{this.initialSet}}
    @searchEnabled={{true}}
    @search={{this.search}}
    as |el|
  >
    {{el.name}}
    [{{el.duration}}
    {{t 'min'}}]
  </Form::Select::Item>
  */
  {
    "id": "has9BFKS",
    "block": "[[[8,[39,0],null,[[\"@selected\",\"@placeholder\",\"@renderInPlace\",\"@onChange\",\"@multiple\",\"@options\",\"@searchEnabled\",\"@search\"],[[30,1],[30,2],[30,3],[30,4],true,[30,0,[\"initialSet\"]],true,[30,0,[\"search\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[1,[30,5,[\"name\"]]],[1,\"\\n  [\"],[1,[30,5,[\"duration\"]]],[1,\"\\n  \"],[1,[28,[35,1],[\"min\"],null]],[1,\"]\\n\"]],[5]]]]]],[\"@selected\",\"@placeholder\",\"@renderInPlace\",\"@onChange\",\"el\"],false,[\"form/select/item\",\"t\"]]",
    "moduleName": "bp-ember-components/components/form/select/item-multiple/index.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});