define("bp-ember-components/components/tabs/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='overflow-x-scroll'>
    <ul class='list-reset tabs flex border-b border-gray-300'>
      {{yield to='header'}}
    </ul>
  </div>
  
  <div class='w-full border-b border-l border-r border-gray-300 p-4'>
    {{yield to='body'}}
  </div>
  */
  {
    "id": "fr6VSrum",
    "block": "[[[10,0],[14,0,\"overflow-x-scroll\"],[12],[1,\"\\n  \"],[10,\"ul\"],[14,0,\"list-reset tabs flex border-b border-gray-300\"],[12],[1,\"\\n    \"],[18,1,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"w-full border-b border-l border-r border-gray-300 p-4\"],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"&header\",\"&body\"],false,[\"yield\"]]",
    "moduleName": "bp-ember-components/components/tabs/index.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});