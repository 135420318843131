define("bp-ember-components/components/data-grid/item/duration/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{t 'minutes' count=@value}}
  */
  {
    "id": "v5n57Qy4",
    "block": "[[[1,[28,[35,0],[\"minutes\"],[[\"count\"],[[30,1]]]]]],[\"@value\"],false,[\"t\"]]",
    "moduleName": "bp-ember-components/components/data-grid/item/duration/index.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});