define("ember-cli-flash/utils/get-with-default", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getWithDefault;
  // This replicates Ember's deprecated `getWithDefault`.
  // Note that, as in the original, `null` is considered a valid value and will
  // not cause the function to return the default value.
  function getWithDefault(objectInstance, key, defaultValue) {
    let value = (0, _object.get)(objectInstance, key);
    if (value === undefined) {
      return defaultValue;
    }
    return value;
  }
});