define("bp-ember-components/components/data-grid-page-object/index", ["exports", "ember-cli-page-object", "ember-table/test-support"], function (_exports, _emberCliPageObject, _testSupport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-next-line ember/no-test-support-import
  var _default = _exports.default = {
    filter: (0, _emberCliPageObject.fillable)('[data-test-fulltext-filter]'),
    table: new _testSupport.TablePage()
  };
});