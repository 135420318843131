define("bp-ember-components/components/dd-menu/item/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @onClick}}
    <button
      type='button'
      class='w-full px-4 py-2 text-left hover:bg-gray-300'
      {{on 'click' @onClick}}
    >
      {{yield}}
    </button>
  {{else}}
    <div type='button' class='w-full px-4 py-2 hover:bg-gray-300'>
      {{yield}}
    </div>
  {{/if}}
  */
  {
    "id": "gS+lR/Iu",
    "block": "[[[41,[30,1],[[[1,\"  \"],[11,\"button\"],[24,0,\"w-full px-4 py-2 text-left hover:bg-gray-300\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,1]],null],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"w-full px-4 py-2 hover:bg-gray-300\"],[14,4,\"button\"],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"@onClick\",\"&default\"],false,[\"if\",\"on\",\"yield\"]]",
    "moduleName": "bp-ember-components/components/dd-menu/item/index.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});