define("bp-ember-components/components/date-select/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/component", "moment"], function (_exports, _component, _templateFactory, _object, _component2, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <FormInput @type='date' @value={{this.value}} @onInput={{this.setValue}} />
  */
  {
    "id": "ychkNV5J",
    "block": "[[[8,[39,0],null,[[\"@type\",\"@value\",\"@onInput\"],[\"date\",[30,0,[\"value\"]],[30,0,[\"setValue\"]]]],null]],[],false,[\"form-input\"]]",
    "moduleName": "bp-ember-components/components/date-select/index.hbs",
    "isStrictMode": false
  });
  let DateSelect = _exports.default = (_class = class DateSelect extends _component2.default {
    setValue(input) {
      const newDate = (0, _moment.default)(input).toDate();
      this.args.onSelect(newDate);
    }
    get value() {
      return (0, _moment.default)(this.args.selected).format('YYYY-MM-DD');
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "setValue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setValue"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DateSelect);
});