define("ember-cli-pagination/validate", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validate = _ember.default.Object.extend();
  Validate.reopenClass({
    internalErrors: [],
    internalError: function (str, obj) {
      this.internalErrors.push(str);
      console.warn(str);
      if (obj) {
        console.warn(obj);
      }
    },
    getLastInternalError: function () {
      return this.internalErrors[this.internalErrors.length - 1];
    }
  });
  var _default = _exports.default = Validate;
});