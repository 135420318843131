define("bp-ember-components/components/icon-button/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Button
    @appearance={{@appearance}}
    @isXSmall={{@isXSmall}}
    @isSmall={{@isSmall}}
    @isXLarge={{@isXLarge}}
    @intent={{@intent}}
    @type={{@type}}
    ...attributes
  >
    {{#if (has-block)}}
      <FaIcon @icon={{@icon}} class='mr-2' />
      {{yield}}
    {{else}}
      <FaIcon @icon={{@icon}} />
    {{/if}}
  </Button>
  */
  {
    "id": "sqPIhUJQ",
    "block": "[[[8,[39,0],[[17,1]],[[\"@appearance\",\"@isXSmall\",\"@isSmall\",\"@isXLarge\",\"@intent\",\"@type\"],[[30,2],[30,3],[30,4],[30,5],[30,6],[30,7]]],[[\"default\"],[[[[1,\"\\n\"],[41,[48,[30,9]],[[[1,\"    \"],[8,[39,3],[[24,0,\"mr-2\"]],[[\"@icon\"],[[30,8]]],null],[1,\"\\n    \"],[18,9,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,3],null,[[\"@icon\"],[[30,8]]],null],[1,\"\\n\"]],[]]]],[]]]]]],[\"&attrs\",\"@appearance\",\"@isXSmall\",\"@isSmall\",\"@isXLarge\",\"@intent\",\"@type\",\"@icon\",\"&default\"],false,[\"button\",\"if\",\"has-block\",\"fa-icon\",\"yield\"]]",
    "moduleName": "bp-ember-components/components/icon-button/index.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});