define("ember-cli-pagination/divide-into-pages", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _ember.default.Object.extend({
    objsForPage: function (page) {
      var range = this.range(page);
      const all = _ember.default.A(this.get('all'));
      return _ember.default.A(all.slice(range.start, range.end + 1));
    },
    totalPages: function () {
      var allLength = parseInt(this.get('all.length'));
      var perPage = parseInt(this.get('perPage'));
      return Math.ceil(allLength / perPage);
    },
    range: function (page) {
      var perPage = parseInt(this.get('perPage'));
      var s = (parseInt(page) - 1) * perPage;
      var e = s + perPage - 1;
      return {
        start: s,
        end: e
      };
    }
  });
});