define("bp-ember-components/components/empty-as-dash", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (eq @value 0)}}
    --
  {{else}}
    {{round @value decimals=2}}
  {{/if}}
  */
  {
    "id": "67c3Plug",
    "block": "[[[41,[28,[37,1],[[30,1],0],null],[[[1,\"  --\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,2],[[30,1]],[[\"decimals\"],[2]]]],[1,\"\\n\"]],[]]]],[\"@value\"],false,[\"if\",\"eq\",\"round\"]]",
    "moduleName": "bp-ember-components/components/empty-as-dash.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});