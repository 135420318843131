define("bp-ember-components/components/data-grid/cashbook/kind/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/service", "@glimmer/component"], function (_exports, _component, _templateFactory, _service, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (eq @row.kind 'visit')}}
    {{#if (feature-flag 'menu:admin:overview')}}
      <Link @route='authenticated.admin.visit' @model={{@value.id}}>
        {{t 'cashbook.isVisit'}}
      </Link>
    {{else}}
      {{t 'cashbook.isVisit'}}
    {{/if}}
  {{else if (eq @row.kind 'wage')}}
    {{@value.employee.fullName}},{{t 'models.wage._modelName' count=1}}
  {{else if (eq @row.kind 'manual')}}
    <SafeDelete @onConfirm={{fn @onClick @row}} />
  {{/if}}
  */
  {
    "id": "wfdfC4u6",
    "block": "[[[41,[28,[37,1],[[30,1,[\"kind\"]],\"visit\"],null],[[[41,[28,[37,2],[\"menu:admin:overview\"],null],[[[1,\"    \"],[8,[39,3],null,[[\"@route\",\"@model\"],[\"authenticated.admin.visit\",[30,2,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,4],[\"cashbook.isVisit\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,4],[\"cashbook.isVisit\"],null]],[1,\"\\n\"]],[]]]],[]],[[[41,[28,[37,1],[[30,1,[\"kind\"]],\"wage\"],null],[[[1,\"  \"],[1,[30,2,[\"employee\",\"fullName\"]]],[1,\",\"],[1,[28,[35,4],[\"models.wage._modelName\"],[[\"count\"],[1]]]],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1,[\"kind\"]],\"manual\"],null],[[[1,\"  \"],[8,[39,5],null,[[\"@onConfirm\"],[[28,[37,6],[[30,3],[30,1]],null]]],null],[1,\"\\n\"]],[]],null]],[]]]],[]]]],[\"@row\",\"@value\",\"@onClick\"],false,[\"if\",\"eq\",\"feature-flag\",\"link\",\"t\",\"safe-delete\",\"fn\"]]",
    "moduleName": "bp-ember-components/components/data-grid/cashbook/kind/index.hbs",
    "isStrictMode": false
  });
  let Kind = _exports.default = (_class = class Kind extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "session", _descriptor, this);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Kind);
});