define("bp-ember-components/lib/customer", ["exports", "moment", "@ember/utils"], function (_exports, _moment, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.daysTillNextBirthday = daysTillNextBirthday;
  _exports.nextBirthday = nextBirthday;
  function nextBirthday(birth) {
    if ((0, _utils.isNone)(birth)) {
      return undefined;
    }
    let nextBirthDay = (0, _moment.default)(birth).year((0, _moment.default)().year());
    if ((0, _moment.default)().diff(nextBirthDay) > 0) {
      nextBirthDay.add('1', 'years');
    }
    return nextBirthDay;
  }
  function daysTillNextBirthday(birth) {
    let next = nextBirthday(birth);
    if ((0, _utils.isNone)(next)) {
      // This is a little hack needed to be able to sort by this property
      // It's not possible to have birthday in 366 days and it's fine to
      // sort those records at the end of list by having so big value there.
      return 366;
    } else {
      return next.diff((0, _moment.default)(), 'days');
    }
  }
});