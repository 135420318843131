define("@frontile/changeset-form/components/changeset-form/fields/radio", ["exports", "@ember/component", "@frontile/changeset-form/components/changeset-form/fields/base", "@ember/object", "@ember/template-factory"], function (_exports, _component, _base, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <FormRadio
    @onChange={{this.handleChange}}
    @checked={{this.value}}
    @hint={{@hint}}
    @value={{@value}}
    @name={{@name}}
    @containerClass={{@containerClass}}
    @size={{@size}}
    ...attributes
    {{on "blur" this.validate}}
  >
    {{#if (has-block)}}
      {{yield}}
    {{else}}
      {{@label}}
    {{/if}}
  </FormRadio>
  */
  {
    "id": "ZcTDEVaE",
    "block": "[[[8,[39,0],[[17,1],[4,[38,1],[\"blur\",[30,0,[\"validate\"]]],null]],[[\"@onChange\",\"@checked\",\"@hint\",\"@value\",\"@name\",\"@containerClass\",\"@size\"],[[30,0,[\"handleChange\"]],[30,0,[\"value\"]],[30,2],[30,3],[30,4],[30,5],[30,6]]],[[\"default\"],[[[[1,\"\\n\"],[41,[48,[30,8]],[[[1,\"    \"],[18,8,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,7]],[1,\"\\n\"]],[]]]],[]]]]]],[\"&attrs\",\"@hint\",\"@value\",\"@name\",\"@containerClass\",\"@size\",\"@label\",\"&default\"],false,[\"form-radio\",\"on\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "@frontile/changeset-form/components/changeset-form/fields/radio.hbs",
    "isStrictMode": false
  });
  let ChangesetFormFieldsRadio = _exports.default = (_class = class ChangesetFormFieldsRadio extends _base.default {
    async handleChange(value, event) {
      event.preventDefault();
      this.args.changeset.set(this.args.fieldName, value);
      await this.validate();
      if (typeof this.args.onChange === 'function') {
        this.args.onChange(value, event);
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "handleChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleChange"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChangesetFormFieldsRadio);
});