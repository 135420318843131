define("bp-ember-components/components/data-grid/sort/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @columnMeta.isSorted}}
    {{#if @columnMeta.isSortedAsc}}
      <FaIcon @icon='sort-up' class='text-gray-500' />
    {{else}}
      <FaIcon @icon='sort-down' class='text-gray-500' />
    {{/if}}
  {{/if}}
  */
  {
    "id": "NAJwBH/v",
    "block": "[[[41,[30,1,[\"isSorted\"]],[[[41,[30,1,[\"isSortedAsc\"]],[[[1,\"    \"],[8,[39,1],[[24,0,\"text-gray-500\"]],[[\"@icon\"],[\"sort-up\"]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,1],[[24,0,\"text-gray-500\"]],[[\"@icon\"],[\"sort-down\"]],null],[1,\"\\n\"]],[]]]],[]],null]],[\"@columnMeta\"],false,[\"if\",\"fa-icon\"]]",
    "moduleName": "bp-ember-components/components/data-grid/sort/index.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});