define("bp-ember-components/validations/reservation", ["exports", "ember-changeset-validations/validators", "bp-ember-components/validators/fromDate", "bp-ember-components/validators/toDate"], function (_exports, _validators, _fromDate, _toDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    start: [(0, _validators.validatePresence)(true), (0, _fromDate.default)()],
    end: [(0, _validators.validatePresence)(true), (0, _toDate.default)()],
    note: [],
    willNotify: [],
    customer: [(0, _validators.validatePresence)(true)],
    employee: [(0, _validators.validatePresence)(true)],
    notification: [],
    items: []
  };
});