define("ember-file-upload/helpers/file-queue", ["exports", "@ember/component/helper", "@ember/service", "@ember/object"], function (_exports, _helper, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
    `file-queue` helper is one of the core primitives of ember-file-upload.
    It has three different flavors of invocation. The first is
    a bare call, which will return the master queue:
  
    ```hbs
    {{#with (file-queue) as |queue|}}
      {{queue.progress}}%
    {{/with}}
    ```
  
    If called with a `name` property, it will return the queue
    of that name:
  
    ```hbs
    {{#with (file-queue name="photos") as |queue|}}
      {{queue.progress}}%
    {{/with}}
    ```
  
    @class FileQueue
    @param {string} [name] The name of the queue to get information on
    @return {Queue} A collection of all queues, or a specific queue.
   */
  var _default = _exports.default = _helper.default.extend({
    fileQueue: (0, _service.inject)(),
    compute(_, hash) {
      let queues = (0, _object.get)(this, 'fileQueue');
      let queueName = hash['name'];
      if (queueName) {
        let queue = queues.find(queueName) || queues.create(queueName);
        return queue;
      }
      return queues;
    }
  });
});