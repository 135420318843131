define("ember-metrics/metrics-adapters/mixpanel", ["exports", "@ember/debug", "ember-metrics/-private/utils/object-transforms", "ember-metrics/-private/utils/remove-from-dom", "ember-metrics/metrics-adapters/base"], function (_exports, _debug, _objectTransforms, _removeFromDom, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Mixpanel extends _base.default {
    toStringExtension() {
      return 'Mixpanel';
    }

    // eslint-disable-next-line ember/classic-decorator-hooks
    init() {
      const config = {
        ...{
          batch_requests: true
        },
        ...this.config
      };
      const {
        token
      } = config;
      delete config.token;
      (false && !(token) && (0, _debug.assert)(`[ember-metrics] You must pass a valid \`token\` to the ${this.toString()} adapter`, token));
      this._injectScript();
      window.mixpanel.init(token, config);
    }

    /* eslint-disable */
    // prettier-ignore
    _injectScript() {
      (function (c, a) {
        if (!a.__SV) {
          var b = window;
          try {
            var d,
              m,
              j,
              k = b.location,
              f = k.hash;
            d = function (a, b) {
              return (m = a.match(RegExp(b + "=([^&]*)"))) ? m[1] : null;
            };
            f && d(f, "state") && (j = JSON.parse(decodeURIComponent(d(f, "state"))), "mpeditor" === j.action && (b.sessionStorage.setItem("_mpcehash", f), history.replaceState(j.desiredHash || "", c.title, k.pathname + k.search)));
          } catch (n) {}
          var l, h;
          window.mixpanel = a;
          a._i = [];
          a.init = function (b, d, g) {
            function c(b, i) {
              var a = i.split(".");
              2 == a.length && (b = b[a[0]], i = a[1]);
              b[i] = function () {
                b.push([i].concat(Array.prototype.slice.call(arguments, 0)));
              };
            }
            var e = a;
            "undefined" !== typeof g ? e = a[g] = [] : g = "mixpanel";
            e.people = e.people || [];
            e.toString = function (b) {
              var a = "mixpanel";
              "mixpanel" !== g && (a += "." + g);
              b || (a += " (stub)");
              return a;
            };
            e.people.toString = function () {
              return e.toString(1) + ".people (stub)";
            };
            l = "disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(" ");
            for (h = 0; h < l.length; h++) c(e, l[h]);
            var f = "set set_once union unset remove delete".split(" ");
            e.get_group = function () {
              function a(c) {
                b[c] = function () {
                  call2_args = arguments;
                  call2 = [c].concat(Array.prototype.slice.call(call2_args, 0));
                  e.push([d, call2]);
                };
              }
              for (var b = {}, d = ["get_group"].concat(Array.prototype.slice.call(arguments, 0)), c = 0; c < f.length; c++) a(f[c]);
              return b;
            };
            a._i.push([b, d, g]);
          };
          a.__SV = 1.2;
          b = c.createElement("script");
          b.type = "text/javascript";
          b.async = !0;
          b.src = "undefined" !== typeof MIXPANEL_CUSTOM_LIB_URL ? MIXPANEL_CUSTOM_LIB_URL : "file:" === c.location.protocol && "//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\/\//) ? "https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js" : "//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";
          d = c.getElementsByTagName("script")[0];
          d.parentNode.insertBefore(b, d);
        }
      })(document, window.mixpanel || []);
    }
    /* eslint-enable */

    identify(options = {}) {
      const compactedOptions = (0, _objectTransforms.compact)(options);
      const {
        distinctId
      } = compactedOptions;
      const props = (0, _objectTransforms.without)(compactedOptions, 'distinctId');
      if ((0, _objectTransforms.hasOwnProperties)(props)) {
        window.mixpanel.identify(distinctId);
        window.mixpanel.people.set(props);
      } else {
        window.mixpanel.identify(distinctId);
      }
    }
    trackEvent(options = {}) {
      const compactedOptions = (0, _objectTransforms.compact)(options);
      const {
        event
      } = compactedOptions;
      const props = (0, _objectTransforms.without)(compactedOptions, 'event');
      if ((0, _objectTransforms.hasOwnProperties)(props)) {
        window.mixpanel.track(event, props);
      } else {
        window.mixpanel.track(event);
      }
    }
    trackPage(options = {}) {
      const event = {
        event: 'page viewed'
      };
      const mergedOptions = {
        ...event,
        ...options
      };
      this.trackEvent(mergedOptions);
    }
    alias(options = {}) {
      const compactedOptions = (0, _objectTransforms.compact)(options);
      const {
        alias,
        original
      } = compactedOptions;
      if (original) {
        window.mixpanel.alias(alias, original);
      } else {
        window.mixpanel.alias(alias);
      }
    }
    willDestroy() {
      (0, _removeFromDom.default)('script[src*="mixpanel"]');
      delete window.mixpanel;
    }
  }
  _exports.default = Mixpanel;
});