define("ember-file-upload/components/file-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "WuicrItU",
    "block": "[[[10,\"input\"],[15,1,[30,0,[\"for\"]]],[15,\"accept\",[30,0,[\"accept\"]]],[15,\"capture\",[30,0,[\"capture\"]]],[15,\"multiple\",[30,0,[\"multiple\"]]],[15,\"disabled\",[30,0,[\"disabled\"]]],[15,\"onchange\",[28,[37,0],[[30,0],\"change\"],[[\"value\"],[\"target.files\"]]]],[14,\"hidden\",\"\"],[14,4,\"file\"],[12],[13],[18,1,[[30,0,[\"queue\"]]]]],[\"&default\"],false,[\"action\",\"yield\"]]",
    "moduleName": "ember-file-upload/components/file-upload/template.hbs",
    "isStrictMode": false
  });
});