define("bp-ember-components/components/link/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <LinkTo @route={{@route}} @model={{@model}} class='text-pink-500'>
    {{yield}}
  </LinkTo>
  */
  {
    "id": "VS2Blqjq",
    "block": "[[[8,[39,0],[[24,0,\"text-pink-500\"]],[[\"@route\",\"@model\"],[[30,1],[30,2]]],[[\"default\"],[[[[1,\"\\n  \"],[18,3,null],[1,\"\\n\"]],[]]]]]],[\"@route\",\"@model\",\"&default\"],false,[\"link-to\",\"yield\"]]",
    "moduleName": "bp-ember-components/components/link/index.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});