define("bp-ember-components/components/data-grid/percent/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{this.valueRounded}}%
  */
  {
    "id": "Jx7a0oK0",
    "block": "[[[1,[30,0,[\"valueRounded\"]]],[1,\"%\"]],[],false,[]]",
    "moduleName": "bp-ember-components/components/data-grid/percent/index.hbs",
    "isStrictMode": false
  });
  class DataGridPercentComponent extends _component2.default {
    get valueRounded() {
      return Math.round(this.args.value).toFixed(1);
    }
  }
  _exports.default = DataGridPercentComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DataGridPercentComponent);
});