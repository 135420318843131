define("bp-ember-components/validations/signup", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    firstName: [(0, _validators.validatePresence)(true)],
    lastName: [(0, _validators.validatePresence)(true)],
    identificationNumber: [(0, _validators.validatePresence)(true)],
    phone: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({
      regex: /^[+]?[()/0-9. -]{9,}$/,
      allowBlank: true
    })],
    email: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({
      type: 'email',
      allowBlank: true
    })],
    employeeCount: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)()],
    discountCode: []
  };
});