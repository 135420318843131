define("ember-intl/-private/store/translation", ["exports", "ember-intl/-private/utils/flatten", "ember-intl/-private/utils/parse"], function (_exports, _flatten, _parse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); } /**
 * Copyright 2015, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */
  /**
   * @remarks
   * `tests/unit/helpers/t-test.ts` has a test that asserts that numbers are
   * acceptable as translations and converted to strings.
   */

  class Translation {
    get localeName() {
      return this._localeName;
    }
    constructor(localeName) {
      _defineProperty(this, "translations", new Map());
      _defineProperty(this, "asts", new Map());
      _defineProperty(this, "_localeName", void 0);
      this._localeName = localeName;
    }
    addTranslations(translations) {
      const flatTranslations = (0, _flatten.default)(translations);
      for (const key in flatTranslations) {
        let translation = flatTranslations[key];

        // If it's not a string, coerce it to one.
        if (typeof translation !== 'string') {
          translation = `${translation}`;
        }
        this.translations.set(key, translation);
        this.asts.set(key, (0, _parse.default)(translation));
      }
    }
    find(key) {
      if (this.has(key)) {
        return {
          ast: this.asts.get(key),
          original: this.translations.get(key)
        };
      }
    }
    has(key) {
      return this.translations.has(key);
    }
  }
  var _default = _exports.default = Translation;
});