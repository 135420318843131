define("bp-ember-components/components/x-date/long/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{format-date
    @value
    year='numeric'
    month='numeric'
    day='numeric'
    hour='numeric'
    minute='numeric'
  }}
  */
  {
    "id": "kbZsADOK",
    "block": "[[[1,[28,[35,0],[[30,1]],[[\"year\",\"month\",\"day\",\"hour\",\"minute\"],[\"numeric\",\"numeric\",\"numeric\",\"numeric\",\"numeric\"]]]]],[\"@value\"],false,[\"format-date\"]]",
    "moduleName": "bp-ember-components/components/x-date/long/index.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});