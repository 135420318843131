define("bp-ember-components/components/data-grid/supply/is-saving/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @row.isSaving}}
    <i class='icon loading spinner'></i>
  {{else if @value}}
    <XDate::Long @value={{@value}} />
  {{else if @row.item}}
    <i class='disabled icon save'></i>
  {{else}}
    <i class='disabled hand icon left point'></i>
  {{/if}}
  */
  {
    "id": "H1drgK0s",
    "block": "[[[41,[30,1,[\"isSaving\"]],[[[1,\"  \"],[10,\"i\"],[14,0,\"icon loading spinner\"],[12],[13],[1,\"\\n\"]],[]],[[[41,[30,2],[[[1,\"  \"],[8,[39,1],null,[[\"@value\"],[[30,2]]],null],[1,\"\\n\"]],[]],[[[41,[30,1,[\"item\"]],[[[1,\"  \"],[10,\"i\"],[14,0,\"disabled icon save\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,\"i\"],[14,0,\"disabled hand icon left point\"],[12],[13],[1,\"\\n\"]],[]]]],[]]]],[]]]],[\"@row\",\"@value\"],false,[\"if\",\"x-date/long\"]]",
    "moduleName": "bp-ember-components/components/data-grid/supply/is-saving/index.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});