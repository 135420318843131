define("bp-ember-components/components/form/select/language/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Form::Select
    @options={{@options}}
    @onChange={{@onChange}}
    @renderInPlace={{@renderInPlace}}
    @searchEnabled={{false}}
    @selected={{@selected}}
    as |language|
  >
    {{language.name}}
  </Form::Select>
  */
  {
    "id": "caqttzII",
    "block": "[[[8,[39,0],null,[[\"@options\",\"@onChange\",\"@renderInPlace\",\"@searchEnabled\",\"@selected\"],[[30,1],[30,2],[30,3],false,[30,4]]],[[\"default\"],[[[[1,\"\\n  \"],[1,[30,5,[\"name\"]]],[1,\"\\n\"]],[5]]]]]],[\"@options\",\"@onChange\",\"@renderInPlace\",\"@selected\",\"language\"],false,[\"form/select\"]]",
    "moduleName": "bp-ember-components/components/form/select/language/index.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});