define("bp-ember-components/validations/voucher", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      min: 1,
      max: 40
    })],
    sellingPrice: [(0, _validators.validateNumber)({
      positive: true
    })],
    barcode: [(0, _validators.validatePresence)(true)],
    vat: [(0, _validators.validatePresence)(true)],
    entity: [(0, _validators.validatePresence)(true)],
    validUntil: [(0, _validators.validatePresence)(true)],
    note: []
  };
});