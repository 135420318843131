define("bp-ember-components/validations/settlement-item", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      min: 1
    })],
    amount: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({
      positive: true
    })],
    price: [(0, _validators.validatePresence)(true)],
    vat: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({
      integer: true
    })],
    when: [(0, _validators.validatePresence)(true), (0, _validators.validateDate)()]
  };
});