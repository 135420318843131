define("bp-ember-components/validations/cashbook-entry", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    amount: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)()],
    note: []
  };
});