define("bp-ember-components/components/data-grid/sell/item-group/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{this.allItems}}
  */
  {
    "id": "DKOVxYhy",
    "block": "[[[1,[30,0,[\"allItems\"]]]],[],false,[]]",
    "moduleName": "bp-ember-components/components/data-grid/sell/item-group/index.hbs",
    "isStrictMode": false
  });
  class ItemGroup extends _component2.default {
    get allSales() {
      const {
        value
      } = this.args;
      return value?.filter(sell => sell.get('item.isService') === false) ?? [];
    }
    get allItems() {
      return this.allSales.map(sell => sell.get('item.name')).join(', ');
    }
  }
  _exports.default = ItemGroup;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ItemGroup);
});