define("@frontile/changeset-form/components/changeset-form/fields/radio-group", ["exports", "@ember/component", "@frontile/changeset-form/components/changeset-form/fields/base", "@ember/object", "@ember/template-factory"], function (_exports, _component, _base, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <FormRadioGroup
    @onChange={{this.handleChange}}
    @value={{this.value}}
    @errors={{this.errors}}
  
    @label={{@label}}
    @hint={{@hint}}
    @hasSubmitted={{@hasSubmitted}}
    @hasError={{@hasError}}
    @showError={{@showError}}
    @containerClass={{@containerClass}}
    @size={{@size}}
    @isInline={{@isInline}}
  
    ...attributes
    as |Radio|
  >
    {{yield Radio}}
  </FormRadioGroup>
  
  */
  {
    "id": "9NgEIboZ",
    "block": "[[[8,[39,0],[[17,1]],[[\"@onChange\",\"@value\",\"@errors\",\"@label\",\"@hint\",\"@hasSubmitted\",\"@hasError\",\"@showError\",\"@containerClass\",\"@size\",\"@isInline\"],[[30,0,[\"handleChange\"]],[30,0,[\"value\"]],[30,0,[\"errors\"]],[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8],[30,9]]],[[\"default\"],[[[[1,\"\\n  \"],[18,11,[[30,10]]],[1,\"\\n\"]],[10]]]]],[1,\"\\n\"]],[\"&attrs\",\"@label\",\"@hint\",\"@hasSubmitted\",\"@hasError\",\"@showError\",\"@containerClass\",\"@size\",\"@isInline\",\"Radio\",\"&default\"],false,[\"form-radio-group\",\"yield\"]]",
    "moduleName": "@frontile/changeset-form/components/changeset-form/fields/radio-group.hbs",
    "isStrictMode": false
  });
  let ChangesetFormFieldsRadioGroup = _exports.default = (_class = class ChangesetFormFieldsRadioGroup extends _base.default {
    async handleChange(value, event) {
      event.preventDefault();
      this.args.changeset.set(this.args.fieldName, value);
      await this.validate();
      if (typeof this.args.onChange === 'function') {
        this.args.onChange(value, event);
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "handleChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleChange"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChangesetFormFieldsRadioGroup);
});