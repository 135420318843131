define("bp-ember-components/validations/item-dispense", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    count: [(0, _validators.validateNumber)({
      positive: true
    })],
    employee: [(0, _validators.validatePresence)(true)],
    item: [(0, _validators.validatePresence)(true)]
  };
});