define("bp-ember-components/components/table/tfoot/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <tfoot class='bg-gray-400'>
    {{yield}}
  </tfoot>
  */
  {
    "id": "C9OApPgS",
    "block": "[[[10,\"tfoot\"],[14,0,\"bg-gray-400\"],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "bp-ember-components/components/table/tfoot/index.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});