define("bp-ember-components/components/fractal-objects/ember-table/index", ["exports", "fractal-page-object"], function (_exports, _fractalPageObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.EmberTable = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  const EmberTable = _exports.EmberTable = (0, _fractalPageObject.selector)('[data-test-ember-table]', class extends _fractalPageObject.PageObject {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "head", (0, _fractalPageObject.selector)('thead'));
      _defineProperty(this, "body", (0, _fractalPageObject.selector)('tbody', class extends _fractalPageObject.PageObject {
        constructor(...args) {
          super(...args);
          _defineProperty(this, "rows", (0, _fractalPageObject.selector)('tr', class extends _fractalPageObject.PageObject {
            constructor(...args) {
              super(...args);
              _defineProperty(this, "cells", (0, _fractalPageObject.selector)('td'));
            }
          }));
        }
        get rowCount() {
          return this.rows.length;
        }
      }));
    }
    getCell(row, col) {
      return this.body.rows[row].cells[col];
    }
  });
});