define("bp-ember-components/validations/customer", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    firstName: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      min: 1,
      max: 40
    })],
    lastName: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      min: 1,
      max: 40
    })],
    phone: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({
      regex: /^[+]?[()/0-9. -]{9,}$/
    })],
    gender: [(0, _validators.validateInclusion)({
      list: ['male', 'female'],
      allowBlank: true
    })],
    mail: [(0, _validators.validateFormat)({
      allowBlank: true,
      type: 'email'
    })],
    termsConfirmation: (0, _validators.validateInclusion)({
      list: [true],
      allowBlank: true
    }),
    birth: []
  };
});