define("bp-ember-components/components/safe-delete/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <SafeButton
    class='relative block inline-block h-12 w-12 overflow-hidden'
    ...attributes
    @onConfirm={{@onConfirm}}
    @timeout={{5000}}
    as |button|
  >
    <button.trigger
      data-test-confirm
      type='button'
      class='absolute left-0 top-0 block h-full w-full rounded bg-red-500 px-4 py-2 font-bold'
    >
      <FaIcon @icon='trash' />
    </button.trigger>
    <button.safety
      data-test-safety
      type='button'
      class='absolute left-0 top-0 block h-full w-full rounded bg-gray-500 px-4 py-2 font-bold'
    >
      <FaIcon @icon='trash' />
    </button.safety>
  </SafeButton>
  */
  {
    "id": "MhSzI8jz",
    "block": "[[[8,[39,0],[[24,0,\"relative block inline-block h-12 w-12 overflow-hidden\"],[17,1]],[[\"@onConfirm\",\"@timeout\"],[[30,2],5000]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,3,[\"trigger\"]],[[24,\"data-test-confirm\",\"\"],[24,0,\"absolute left-0 top-0 block h-full w-full rounded bg-red-500 px-4 py-2 font-bold\"],[24,4,\"button\"]],null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@icon\"],[\"trash\"]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[30,3,[\"safety\"]],[[24,\"data-test-safety\",\"\"],[24,0,\"absolute left-0 top-0 block h-full w-full rounded bg-gray-500 px-4 py-2 font-bold\"],[24,4,\"button\"]],null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@icon\"],[\"trash\"]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[3]]]]]],[\"&attrs\",\"@onConfirm\",\"button\"],false,[\"safe-button\",\"fa-icon\"]]",
    "moduleName": "bp-ember-components/components/safe-delete/index.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});