define("bp-ember-components/components/link-button/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Button @intent={{@type}} @isRenderless={{true}} as |btn|>
    {{#if @model}}
      <LinkTo
        @route={{@route}}
        @disabled={{@disabled}}
        @model={{@model}}
        class={{btn.classNames}}
        ...attributes
      >
        {{yield}}
      </LinkTo>
    {{else if @href}}
      <a
        href={{@href}}
        disabled={{@disabled}}
        class={{btn.classNames}}
        ...attributes
      >
        {{yield}}
      </a>
    {{else}}
      <LinkTo
        @route={{@route}}
        @disabled={{@disabled}}
        class={{btn.classNames}}
        ...attributes
      >
        {{yield}}
      </LinkTo>
    {{/if}}
  </Button>
  */
  {
    "id": "4QXrAWnB",
    "block": "[[[8,[39,0],null,[[\"@intent\",\"@isRenderless\"],[[30,1],true]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,3],[[[1,\"    \"],[8,[39,2],[[16,0,[30,2,[\"classNames\"]]],[17,4]],[[\"@route\",\"@disabled\",\"@model\"],[[30,5],[30,6],[30,3]]],[[\"default\"],[[[[1,\"\\n      \"],[18,8,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],[[[41,[30,7],[[[1,\"    \"],[11,3],[16,6,[30,7]],[16,\"disabled\",[30,6]],[16,0,[30,2,[\"classNames\"]]],[17,4],[12],[1,\"\\n      \"],[18,8,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,2],[[16,0,[30,2,[\"classNames\"]]],[17,4]],[[\"@route\",\"@disabled\"],[[30,5],[30,6]]],[[\"default\"],[[[[1,\"\\n      \"],[18,8,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]],[]]]],[2]]]]]],[\"@type\",\"btn\",\"@model\",\"&attrs\",\"@route\",\"@disabled\",\"@href\",\"&default\"],false,[\"button\",\"if\",\"link-to\",\"yield\"]]",
    "moduleName": "bp-ember-components/components/link-button/index.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});