define("ember-cli-pagination/test-helpers", ["exports", "ember", "ember-cli-pagination/divide-into-pages"], function (_exports, _ember, _divideIntoPages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TestHelpers = _ember.default.Object.extend({
    responseHash: function () {
      var page = this.pageFromRequest(this.request);
      var k = "" + this.name + "s";
      var res = {};
      res[k] = this.objsForPage(page);
      res.meta = {
        total_pages: this.totalPages()
      };
      return res;
    },
    divideObj: function () {
      var perPage = this.perPageFromRequest(this.request);
      return _divideIntoPages.default.create({
        perPage: perPage,
        all: this.all
      });
    },
    objsForPage: function (page) {
      return this.divideObj().objsForPage(page);
    },
    pageFromRequest: function (request) {
      var res = request.queryParams.page;
      return parseInt(res);
    },
    perPageFromRequest: function (request) {
      var res = request.queryParams.per_page;
      return parseInt(res);
    },
    totalPages: function () {
      return this.divideObj().totalPages();
    }
  });
  TestHelpers.reopenClass({
    responseHash: function (request, all, name) {
      return this.create({
        request: request,
        all: all,
        name: name
      }).responseHash();
    }
  });
  var _default = _exports.default = TestHelpers;
});