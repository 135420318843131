define("bp-ember-components/validators/toDate", ["exports", "moment", "ember-changeset-validations/utils/validation-errors"], function (_exports, _moment, _validationErrors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateToDate;
  function validateToDate() {
    return (key, newValue, oldValue, changes, content) => {
      if ((0, _moment.default)(newValue).isAfter((0, _moment.default)(content.start))) {
        return true;
      }
      return (0, _validationErrors.default)('toDate', {
        type: 'greaterThan'
      });
    };
  }
});